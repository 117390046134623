import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2"},[_c('h6',[_vm._v(_vm._s(_vm.index + 1)+". "+_vm._s(_vm.name))]),_c(VDataTable,{ref:("sortableTable-" + _vm.index),attrs:{"headers":_vm.headers,"items":_vm.answers,"id":"sortable","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"py-4"},[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
              max_value: 100,
            }),expression:"{\n              required: true,\n              max_value: 100,\n            }"}],staticClass:"score-input",attrs:{"name":("score-" + (item.quizId)),"value":item.score,"required":true,"state":_vm.validateState(("score-" + (item.quizId))),"invalid-feedback":_vm.errors.first(("score-" + (item.quizId))),"data-vv-as":"Điểm","input-number":"","maxlength":"3"},on:{"update:value":function($event){return _vm.$set(item, "score", $event)}}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }